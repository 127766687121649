import React from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';

export const Section = ({ children, className, noPaddingTop }) => {
  return (
    <div className={cx(styles.section, className, noPaddingTop && styles.noPaddingTop)}>
      {children}
    </div>
  )
};

export const SectionTitle = ({ children, className }) => {
  return (
    <h2 className={cx('H2', styles.title, className)}>{children}</h2>
  )
};

export const GraphsWrapper = ({ children, className }) => {
  return (
    <div className={cx(styles.graphs, className)}>
      {children}
    </div>
  )
};

export const GraphBox = ({ children, fullWidth, className }) => {
  return (
    <div className={cx(fullWidth ? styles.graphFullWidth : styles.graph, className)}>
      <div className={styles.graphInner}>
        {children}
      </div>
    </div>
  )
};